import { lazy } from "react"

// 放置全屏页面
const rootRoutes = [
  {
    path: '/login',
    component: lazy(() => import('@/views/Login')),
    authData: {
      loginRequired: false, //是否验证登录
      permsRequired: false, //是否验证权限
    }
  },
  {
    path: '/404',
    component: lazy(() => import('@/views/Error/404')),
    authData: {
      loginRequired: false, //是否验证登录
      permsRequired: false, //是否验证权限
    }
  },
  {
    path: '/enterprise_create',
    component: lazy(() => import('@/views/EnterpriseCreate/index'))
  },
  {
    path: '/',
    component: lazy(() => import('@/views/Home')),
  }
]

// 放置嵌套在首页的页面
const otherRoutes = [
  {
    path: '/enterprise/list',
    component: lazy(() => import('@/views/Enterprise/List')),
  },
  {
    path: '/enterprise/demand/list',
    component: lazy(() => import('@/views/Enterprise/Demand/List')),
  },
  {
    path: '/enterprise/demand/detail',
    component: lazy(() => import('@/views/Enterprise/Demand/Detail')),
  }
]

export {
  rootRoutes,
  otherRoutes
}