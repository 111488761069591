import { get, post } from "@/utils/request"

export class UserApi {
    static async getUserInfo() {
        const url = '/api/account/me'
        return get(url)
    }

    static async logout() {
        const url ='/api/account/logout'
        return post(url)
    }

    static async getModules() {
        const url = '/api/account/modules'
        return get(url)
    }
}