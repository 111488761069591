const SETTINGS = {
    APP_ID: 'venus',
    API_HOST: 'https://backend.venus.demo.aks.chilunyc.com',
    DEPLOY_ENV: 'development',
    FARM_SITE_URL: 'https://farm.staging.chilunyc.com',
    DEVELOPER_SITE_URL: 'https://staging.developer.chilunyc.com',
    TEST_SITE_URL: 'https://test.staging.chilunyc.com',
    CLIENT_SITE_URL: 'https://staging.client.chilunyc.com',
    DOCUMENT_SITE_URL: 'https://staging.document.chilunyc.com',
    FORCE_REFRESH: false,
    SSO_HOST: 'https://sso.staging.chilunyc.com',

    TRACKER_SDK: null,
    PUSHER_SDk: `<script type="text/javascript" src="https://cdn.plugins.chilunyc.com/gear-pusher/gear-pusher.min.js"></script>
<script>
    GearPusher.initialize({
        serverDomain:"wss://farm.staging.chilunyc.com"
    })
</script>`,

};

export default SETTINGS
