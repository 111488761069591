import axios from 'axios';
import storage from 'storejs';
import {message} from 'antd';
import {trimParams, urlParamsEncode} from '@/utils/common';
// import {ssoLogin,ssoLogout} from '@/components/common/SsoLogin/sso-auth.js';


//返回结果的状态码字典
// const RESPONSE_STATUS = [200, 400, 401, 403, 404, 500]
// const RESULT_CODE_DICT = {
//     200: {
//         '0': '成功',
//     },
//     400: {
//         '10000': '请求参数无效',
//         '10001': '资源存在，无需重复创建',
//         '10002': '参数缺失',
//     },
//     401: {
//         '10100': '用户需要登录：Token无效或缺失',
//         '10101': '用户冻结',
//         '10102': 'Token无效',
//         '10103': 'Token缺失',
//         '10104': 'Authentication Key无效',
//         '10105': 'Token过期'
//     },
//     403: {
//         '10300': '没有权限访问',
//         '10301': '权限密钥无效',
//     },
//     404: {
//         '10400': '所请求的资源不存在'
//     },
//     500: {
//         '10500': '系统错误'
//     }
// }
// const RESPONSE_EXAMPLE= {'result': true, 'result_code': '0', 'result_text': 'ok', "message": 'ok', 'data': []};


// axios.defaults.headers.common['retry'] = 3;
// axios.defaults.headers.common['retryDelay'] = 1000;
// axios.defaults.headers.common['retryCount'] = 1;

const service = axios.create({
    // baseURL: SETTINGS.API_HOST,
    timeout: 300000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    }
});


let pending = []; //存储每个请求的取消函数和请求标识
let cancelToken = axios.CancelToken;
export let removePending = (urlKey,noParams = false) => {
    pending = pending.filter((item)=>{
        if(
          item.urlKey === urlKey ||
          ( noParams && item.urlKey.indexOf(urlKey) > -1 )
        ){
            item.f();
            return false;
        }else{
            return true;
        }
    })
}


//请求拦截器
service.interceptors.request.use(function (config) {
    //对请求添加Token
    if(storage.get('token')){
        config.headers.Authorization = storage.get('token') ? 'Token ' + storage.get('token') : '';
    }

    let params =  '@PARAMS' + (config.params?urlParamsEncode(config.params):'');
    let urlKey = config.url + '&' + config.method + params;


    config.cancelToken = new cancelToken( (c)=>{
        if(config.isAbort){
            pending.push({ urlKey: urlKey, f: c });
        }
    });

    return config;
}, function (error) {
    return Promise.reject(error);
});

//响应拦截器
service.interceptors.response.use((response) => {
    // console.log(response.headers);

    let data = response.data;
    let pagination_properties = response.headers['x-pagination']
    if (pagination_properties) {
        data.pagination_properties = JSON.parse(pagination_properties);
    }


    let content_disposition = response.headers['content-disposition']
    if(response.headers['content-disposition']){
        data.content_disposition = content_disposition
        data.data = data;
    }

    let Impersonator = response.headers['x-impersonator']
    if (Impersonator) {
        if(typeof window.windowState == "object"){
            window.windowState.isInseeket = decodeURIComponent(Impersonator)
        }

    }else{
        if(typeof window.windowState == "object"){
            window.windowState.isInseeket = null
        }
    }

    if(typeof window.windowState == "object"){
        window.windowState.notServe = false
    }


    return response;
}, error => {
    console.log('响应错误-response', error.config, error.response);

    //断网
    if(!window.navigator.onLine){
        return Promise.reject(error)
    }

    //401
    let response = error.response ? error.response : {};
    // if (response.status === 401) {
    //     storage.remove('token');
    //     window.location.href = '/login'
    // }
    if (response.status === 500) {
        message.error('服务器内部错误');
    }

    //服务异常
    if(error.message === 'Network Error') {
        var config = error.config;
        var isRetry = config.retryObj ? config.retryObj.isRetry : false;
        if (!config || !config.retryObj || !config.retryObj.retry || isRetry) return Promise.reject(error);
        config.retryObj.retryCount = config.retryObj.retryCount || 0;
        if (config.retryObj.retryCount >= config.retryObj.retry) {

            //几次全部都是错误时 -- 服务异常
            if(typeof window.windowState == "object"){
                window.windowState.notServe = true
            }


            return Promise.reject(error);
        }

        config.retryObj.retryCount += 1;

        var backOff = new Promise((resolve) => {
            setTimeout(function () {
                resolve();
            }, config.retryObj.retryDelay || 1);
        });

        return backOff.then(function () {
            return service(config);
        });
    }


    return Promise.reject(error)
});


// isRetry接口是否需要重试  默认为false
export function get(url, params = {},isAbort = false, retryObj = {isRetry: false, retry: 3, retryDelay: 1000, retryCount: 1}) {
    return service.get(url, {params: trimParams(params), retryObj: retryObj,isAbort:isAbort})
}

export function post(url, data = {}, retryObj = {isRetry: false, retry: 3, retryDelay: 1000, retryCount: 1}) {
    return service.post(url, data, {retryObj: retryObj})
}

export function patch(url, data = {}, retryObj = {isRetry: false, retry: 3, retryDelay: 1000, retryCount: 1}) {
    return service.patch(url, data, {retryObj: retryObj})
}

export function put(url, data = {}, retryObj = {isRetry: false, retry: 3, retryDelay: 1000, retryCount: 1}) {
    return service.put(url, data, {retryObj: retryObj})
}

export function del(url, data = {}, retryObj = {isRetry: false, retry: 3, retryDelay: 1000, retryCount: 1}) {
    return service.delete(url, data, {retryObj: retryObj})
}
export function down(url, params = {}, retryObj = {isRetry: false, retry: 3, retryDelay: 1000, retryCount: 1,isDown:true}) {
    return service.get(url, {responseType:'blob',params: trimParams(params), retryObj: retryObj})
}

export default service;


/*export function getRequest(url, params = {}) {
    return service.get(url, { params: trimParams(params) })
}

export function postRequest(url, data = {}) {
    return service.post(url, data)
}

export function patchRequest(url, data = {}) {
    return service.patch(url, data)
}
export function putRequest(url, data = {}) {
    return service.put(url, data)
}

export function deleteRequest(url, data = {}) {
    return service.delete(url, data)
}
const request = {
    service,
    get:getRequest,
    post:postRequest,
    patch:patchRequest,
    put:putRequest,
    delete:deleteRequest,
}

export default request;*/
