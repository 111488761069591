import Loading from "@/components/common/Loading";
// import { ssoLogin } from "@/components/common/SsoLoginModule/sso-auth";
// import SETTINGS from "@/settings";
import { inject } from "mobx-react";
import { PureComponent, Suspense } from "react";
import { Route, Switch } from "react-router";
// import storage from 'storejs'

@inject('userStore')
class PrivateRoute extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    render() {
        return (
            <Route
                render={
                    () => {
                        return <Suspense fallback={<Loading
                            fontSize={28}
                            tip='正在加载,请稍后...'
                        />}><this.props.component {...this.props}/></Suspense>
                        // const authData = this.props.authData
                        // const isLogged = storage.get('token') 
                        // if (authData && !authData.loginRequired) {
                        //     if (this.props.path === '/login' && isLogged) {
                        //         return <Redirect to='/'/> 
                        //     }
                        //     return <Suspense fallback={<Loading
                        //         fontSize={28}
                        //         tip='正在加载,请稍后...'
                        //     />}><this.props.component {...this.props}/></Suspense>
                        // } else {
                        //     if (isLogged) {
                        //         if (this.props.path === '/login') {
                        //             return <Redirect to='/'/> 
                        //         } else {
                        //             return <Suspense fallback={<Loading
                        //                 fontSize={28}
                        //                 tip='正在加载,请稍后...'
                        //             />}><this.props.component {...this.props}/></Suspense>
                        //         }
                        //     } else {
                        //         // ssoLogin(SETTINGS.APP_ID, SETTINGS.DEPLOY_ENV)
                        //         return <Redirect to={{pathname: this.props.authPath}}/>
                        //     }   
                        // }
                    }
                }
            />
        )
    }
}

class RenderRoutes extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        console.log(this.props)
        let {routes} = this.props;
        let routeArr = routes;
        return (
            routeArr && routeArr.length > 0
                ?
                <Switch>
                    {routeArr.map((route, i) => {
                        console.log('map', route)
                        return (
                            <PrivateRoute
                                {...this.props}
                                key={route.path || i}
                                path={route.path}
                                exact={route.exact}
                                authed={this.props.authed}
                                component={route.component}
                                children={route.children ? route.children : null}
                                authData={route.authData}
                            />
                        )
                    })}
                    {/* <Redirect to={{pathname: '/404'}}/> */}
                </Switch>
                : null
        )
    }
}

export default RenderRoutes