import React from 'react';
import { rootRoutes } from '@/router/routes'
import { Provider } from 'mobx-react';
import { configure } from 'mobx';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider, message, Spin } from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './App.css';
// import 'antd/dist/antd.less'
import '@/commons/venus-common.scss';
import '@/utils/request.js'
import RenderRoutes from './router/renderRoutes';
import { createBrowserHistory } from 'history';
import RouterStore from './stores/RouterStore';
import SETTINGS from '@/settings';
import UserStore from './stores/UserStore';
import storage from 'storejs'
import { UserApi } from './apis/userApi';



// ******* mbox  *******
// 开启mobx的严格模式，规范数据修改操作只能在action中进行
configure({enforceActions: 'always'})
const browserHistory = createBrowserHistory()
const routerStore = new RouterStore(browserHistory)
console.log(routerStore)

// ******* store  *******
const rootStore = {
  userStore: new UserStore()
};


// *******  antd  *******
moment.locale('zhCN');
message.config({
    top: 100,    //消息距离顶部的位置
    duration: 5, //自动关闭的时长
    maxCount: 3, //最大显示数
    // icon:'', 自定义图标
});
const antIcon = <LoadingOutlined style={{fontSize: 28}} spin/>;
Spin.setDefaultIndicator(antIcon)


class App extends React.Component {

  componentDidMount() {
    const isLogged = storage.get('token') 
    if (isLogged) {
      if (!rootStore.userStore.user) {
        UserApi.getUserInfo().then(result => {
          rootStore.userStore.updateUserInfo(result.data)
        })
      }
    }
  }


  render() {
    return (
      <ConfigProvider locale={zhCN}>
        <Provider {...rootStore}>
          <Router basename='/' forceRefresh={SETTINGS.FORCE_REFRESH ? true : false}>
            <RenderRoutes
              routes={rootRoutes}
              authPath={'/login'}
              />
          </Router>
        </Provider>
      </ConfigProvider>
    )
  }
}

export default App;
