import React from 'react';
import {
    Spin
} from 'antd';
import {
    LoadingOutlined
} from '@ant-design/icons';

import './index.scss';

class Loading extends React.Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        let antIcon = <LoadingOutlined style={{ fontSize: this.props.fontSize }}/>;
        return <div className='loading'>
            <Spin
                indicator={antIcon}
                tip={this.props.tip ? this.props.tip : null}
            />
        </div>
    }
}
export default Loading;
