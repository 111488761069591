import { User } from '@/apis/types';
import { makeAutoObservable } from 'mobx';

// 用户模块store

class UserStore {
  user: User | null = null
  
  constructor () {
    makeAutoObservable(this)
  }

  updateUserInfo (_user: User) {
    this.user = _user
  }
}

export default UserStore;