//获取url参数
import moment from 'moment';
// import OauthApi from '@/farm-apis/OauthApi';
// import wx from 'weixin-js-sdk'
import pinyin from 'pinyin';
// import {get} from '@/utils/request';
// import holiday from '@/utils/holiday.json';

export function trimParams(params = {}) {
    let obj = {};
    for (let o in params) {
        if (params.hasOwnProperty(o) && params[o] !== '') {
            obj[o] = params[o]
        }
    }
    return obj
}


// 判断是否为空  0 false '' undefined [], null都无效
export function isValidValue(value) {
    if (typeof (value) === "undefined" || value === undefined || value === null || value === false || value === '') {
        return false
    }
    if (isNaN(value) && value === 0) {
        return false
    }
    if ((value instanceof Array) && value.length === 0) {
        return false
    }
    if ((typeof value) === 'string' && value.trim() === '') {
        return false
    }
    let result = value ? true : false
    return result
}

//获取文件名后缀.jpg 并转为小写
export function getFileLastName(str) {
    if (str && str !== '') {
        str = String(str);
        let index1 = str.lastIndexOf(".");
        let index2 = str.length;
        let postf = str.substring(index1, index2);
        return postf.toLowerCase()
    } else {
        return ''
    }

}
// 处理URL参数 arr 数组 需要返回数据为数组的对应字段params
export function urlParamsParse(str, arr) {
    let arrList = arr || [];
    let paramStr = str || window.location.search.slice(1);
    let params = {};
    if (paramStr.length <= 0) {
        return params
    }
    paramStr.replace(/([^=&]+)=([^&]*)/g, function (m, key, value) {
        let paramKey = decodeURIComponent(key);
        let paramValue = decodeURIComponent(value);
        arrList.includes(paramKey)

            ?
            params[paramKey] = paramValue !== '' ? paramValue.split(/[;|,|；|，]/g) : []
            :
            params[paramKey] = paramValue

    });
    return params;
}

//将对象处理为URL的参数  urlParamEncode( {status:1, users:['lifanping', 'chuanzong']} )
export function urlParamsEncode(params, encode = false, needQuestionMark = true) {
    let paramStr = '';
    if (params) {
        let paramList = [];
        for (let key in params) {
            let value = params[key];
            if (value instanceof Array) {
                value = value.join()
            } else if (value == null) {
                value = ''
            }
            let itemStr = key + '=' + (encode ? encodeURIComponent(value) : value);
            paramList.push(itemStr)
        }
        paramStr = needQuestionMark ? '?' + paramList.join('&') : paramList.join('&');
    }
    return paramStr
}

//重写localStorage set
export function setStorageExpire(key, value, expire) {
    const obj = {
        value: value,
        time: Date.now(),
        expire: 1000 * 60 * expire  // 单位是分钟
    };
    localStorage.setItem(key, JSON.stringify(obj));
}

// 重写localStorage get
export function getStorageExpire(key, remove = true) {
    const val = localStorage.getItem(key);
    if (val != null) {
        let storageInfo = JSON.parse(val);
        const timeSpan = Date.now() - storageInfo.time;

        if (timeSpan > storageInfo.expire) {
            if (remove) {
                localStorage.removeItem(key);
            }
            return null;
        }
        return storageInfo.value;
    }
    return null;
}


export function copyJsonObj(obj) {
    return JSON.parse(JSON.stringify(obj))
}

export function isUserInList(user, userList) {
    return objIsInArray(user, userList, 'id')
}

//一个对象是否在数组中，key是判断的标准
function objIsInArray(obj, array, key) {
    let isHave = false;
    for (let i = 0; i < array.length; i++) {
        if (array[i][key] === obj[key]) {
            isHave = true;
        }
    }
    return isHave
}

//深度比较两个js对象 相同返回true
export function deepCompare(x, y) {
    var i, l, leftChain, rightChain;

    function compare2Objects(x, y) {
        var p;

        // remember that NaN === NaN returns false
        // and isNaN(undefined) returns true
        if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
            return true;
        }

        // Compare primitives and functions.
        // Check if both arguments link to the same object.
        // Especially useful on the step where we compare prototypes
        if (x === y) {
            return true;
        }

        // Works in case when functions are created in constructor.
        // Comparing dates is a common scenario. Another built-ins?
        // We can even handle functions passed across iframes
        if ((typeof x === 'function' && typeof y === 'function') ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)) {
            return x.toString() === y.toString();
        }

        // At last checking prototypes as good as we can
        if (!(x instanceof Object && y instanceof Object)) {
            return false;
        }

        if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
            return false;
        }

        if (x.constructor !== y.constructor) {
            return false;
        }

        if (x.prototype !== y.prototype) {
            return false;
        }

        // Check for infinitive linking loops
        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
            return false;
        }

        // Quick checking of one object being a subset of another.
        // todo: cache the structure of arguments[0] for performance
        for (p in y) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        for (p in x) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }

            switch (typeof (x[p])) {
                case 'object':
                case 'function':

                    leftChain.push(x);
                    rightChain.push(y);

                    if (!compare2Objects(x[p], y[p])) {
                        return false;
                    }

                    leftChain.pop();
                    rightChain.pop();
                    break;

                default:
                    if (x[p] !== y[p]) {
                        return false;
                    }
                    break;
            }
        }

        return true;
    }

    if (arguments.length < 1) {
        return true; //Die silently? Don't know how to handle such case, please help...
        // throw "Need two or more arguments to compare";
    }

    for (i = 1, l = arguments.length; i < l; i++) {

        leftChain = []; //Todo: this can be cached
        rightChain = [];

        if (!compare2Objects(arguments[0], arguments[i])) {
            return false;
        }
    }

    return true;
}


//两个数组是否有交集
export function haveIntersection(arrayOne, arrayTwo) {
    //item:当前元素的值；index:当前元素的索引；array:当前元素的数组对象；
    return arrayOne.some(function (item, index, array) {
        return arrayTwo.includes(item)
    });
}



//获取url参数
export function getQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}


//日期格式化
export function simpleDateStr(date_string, need_time = true) {
    if (isValidValue(date_string)) {
        let date_array = getDateArray(date_string);
        let result_str = [date_array[0], date_array[1], date_array[2]].join('.');
        if (need_time && date_array.length > 3) {
            result_str = result_str + ' ' + date_array[3]
        }
        return result_str
    } else {
        return ''
    }
}
export function getDateArray(date_string) {
    let new_string = date_string.replace(/[^0-9:]/g, ' ').trim();
    let date_array = new_string.split(/\s+/g);
    let newArray = [parseInt(date_array[0]), parseInt(date_array[1]), parseInt(date_array[2])]
    if (date_array.length > 3) {
        newArray.push(date_array[3])
    }
    return newArray
}

export function commonDateStr(date_string) {
    let date_array = getDateArray(date_string);
    let result_str = [date_array[0], date_array[1], date_array[2]].join('/');
    if (date_array.length > 3) {
        result_str = result_str + ' ' + date_array[3]
    }
    return result_str
}

// export function getWaitDuration(start, end, dayNum = 1, zeroStr = "1分钟") {
//     if (start && end && start != end) {
//         let startTime = moment(start);
//         let endTime = moment(end);
//         if (startTime.isSame(end)) {
//             return zeroStr
//         }
//         let waitTime = moment.duration(endTime - startTime, 'ms');
//         let years = waitTime.get('years');
//         let months = waitTime.get('months');
//         let days = waitTime.get('days');
//         let hours = waitTime.get('hours');
//         let minutes = waitTime.get('minutes');


//         let yearStr = years ? years + '年' : '';
//         let monthsStr = months ? months + '个月' : '';
//         let resultStr = yearStr + monthsStr;
//         let daysStr = '';
//         let hoursStr = '';
//         let minutesStr = minutes != 0 ? minutes + '分钟' : '';
//         if (days >= dayNum) {
//             daysStr = days + '天';
//             if (hours != 0) {
//                 hoursStr = hours + '小时';
//             }
//             minutesStr = '';
//         } else {
//             hoursStr = days * 24 + hours == 0 ? '' : days * 24 + hours + '小时';
//         }
//         if (!resultStr && !days && !hours && !minutes) {
//             return zeroStr
//         } else {
//             yearStr || monthsStr ? hoursStr = minutesStr = '' : null;
//             return resultStr = resultStr + daysStr + hoursStr + minutesStr
//         }
//         return resultStr
//     }
//     return zeroStr
// }

// export function getDurationDays(start, end) {
//     if (!start) {
//         return null
//     }
//     end ? end : moment();
//     let startTime = moment(start);
//     let endTime = moment(end);
//     let days = endTime.diff(startTime, "days");
//     let a = 1;
//     days = days ? days : 1;
//     return days
// }


export function gearDate(date_string) {
    let newString = commonDateStr(date_string);
    return new Date(newString)
}


export function getUUID(len, radix) {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    let uuid = [], i;
    radix = radix || chars.length;

    if (len) {
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        let r;
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    return uuid.join('');
}


/**
 * 验证数据 是数字：返回true；不是数字：返回false
 **/

export function isRealNumber(val) {
    if (parseFloat(val).toString() === "NaN") {
        return false;
    } else {
        return true;
    }
}




// 获取该月的日历信息 参数 YYYY-MM 或者 YYYY-MM-DD
export function everyMonthDays(currentMonthStr) {
    //当月第一天
    let monthStartDay = moment(currentMonthStr).startOf('month').format('YYYY-MM-DD');
    //当月最后一天
    let monthEndDay = moment(currentMonthStr).endOf('month').format('YYYY-MM-DD');

    // 当月第一天所在的周一
    let monthFirstDay = moment(monthStartDay).startOf('week').format('YYYY-MM-DD');
    // 当月最后一天所在的周末
    let monthLastDay = moment(monthEndDay).endOf('week').format('YYYY-MM-DD');
    //当月最后一天所在周的周日 与 当月第一天所在周的周一的相隔天数
    let dayNums = moment(monthLastDay).diff(moment(monthFirstDay), 'days');

    let dayGroups = [];
    for (let i = 0; i < (dayNums + 1) / 7; ++i) {
        let startIndex = i * 7;
        let endIndex = (i + 1) * 7;
        let itemList = [];
        for (let j = startIndex; j < endIndex; j++) {
            let currentDate = moment(monthFirstDay).add(j, 'days');
            let isThisMonth = currentDate.format('YYYY-MM') === moment(currentMonthStr).format('YYYY-MM');
            let dateData = {
                dateStr: currentDate.format('YYYY-MM-DD'),
                year: currentDate.year(),
                month: currentDate.month(),
                day: currentDate.format('D'),
                week: currentDate.weekday(),
                isThisMonth: isThisMonth,
            };
            itemList.push(dateData)
        }
        dayGroups.push(itemList);
    }
    return dayGroups;
}

//根据日期（开始日期与结束日期）获取其中多有的年月（2018-04～2019-12）；
export function getMounthSection(start, end) {
    let startMonth = moment(start).format('YYYY-MM');
    let endMonth = moment(end).format('YYYY-MM');

    let DateArr = [];
    if (moment(endMonth).diff(moment(startMonth), 'months') >= 0) {
        let lengths = moment(endMonth).diff(moment(startMonth), 'months');
        for (let i = 0; i < lengths + 1; i++) {
            let obj = {
                yearMonth: moment(startMonth).add(i, 'months').format('YYYY-MM'),
                year:moment(startMonth).add(i, 'months').format('YYYY'),
                month:moment(startMonth).add(i, 'months').format('MM'),
                monthIndex: new Date(moment(startMonth).add(i, 'months')).getMonth(),
            }

            DateArr.push(obj)
        }
    }
    return DateArr;
}

export function getAllMounthSection(start, end) {
    let startMonth = moment(start).format('YYYY-MM');
    let endMonth = moment(end).format('YYYY-MM');

    let DateArr = [];
    if (moment(endMonth).diff(moment(startMonth), 'months') >= 0) {
        let lengths = moment(endMonth).diff(moment(startMonth), 'months');
        for (let i = 0; i < lengths + 1; i++) {
            let obj = getMonthCenter(moment(startMonth).add(i, 'months').format('YYYY-MM'));
            obj.yearMonth = moment(startMonth).add(i, 'months').format('YYYY-MM');
            let days = [];
            for (let j = 1; j <= obj.daysLength; j++) {
                days.push(j);
            }
            obj.days = days;
            DateArr.push(obj)
        }
    }
    return DateArr;
}
//根据年月返回该月的年与月与天数 getMonthCenter('2019-02');
function getMonthCenter(val) {
    let daysLength = moment(val).daysInMonth();
    let year = moment(val).get('year');
    let month = moment(val).get('month') + 1;
    if (month < 10) {
        month = '0' + month
    }
    return {
        year: year,
        month: month,
        daysLength: daysLength,
    };
}

export function getFormat(currentDate) {
    let todayStr = moment().format("YYYY-MM-DD");
    let yesterdayStr = moment().subtract(1, 'days').format("YYYY-MM-DD");
    let beforeYesterdayStr = moment().subtract(2, 'days').format("YYYY-MM-DD");
    // let tomorrowStr = moment().add(1, 'days').format("YYYY-MM-DD");
    // let afterTomorrowStr = moment().add(2, 'days').format("YYYY-MM-DD");
    let currentDateStr = currentDate && moment(currentDate).format("YYYY-MM-DD");
    if (currentDateStr === todayStr) {
        return '今天'
    } else if (currentDateStr === yesterdayStr) {
        return '昨天'
    } else if (currentDateStr === beforeYesterdayStr) {
        return '前天'
    }
    return ''

}

//设置cookie   c_name:存储的key   value:值   expiredays:过期时间   domain：指定域名
export function setCookie(c_name,value,expiredays,domain) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie = c_name+ "=" +JSON.stringify(value)+((expiredays==null) ? "" : ";expires="+exdate.toGMTString())+";path=/;domain=" + domain;
}


export function isPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = ['Android', 'iPhone',
        'SymbianOS', 'Windows Phone',
        'iPad', 'iPod'
    ];
    var flag = true;
    for (var i = 0; i < Agents.length; i++) {
        if (userAgentInfo.indexOf(Agents[i]) !== -1) {
            flag = false;
            break;
        }
    }
    return flag;
}

export function isText(data) {
    let result = false;
    if (!data || (typeof data) === 'string' || data === true || data === null) {
        result = true;
    }

    return result;

}

export function downFileBlob(data, fileName = null) {
    fileName = fileName || ((data.content_disposition && data.content_disposition.split("filename*=utf-8''")[1]) || '下载文件');
    let fileNameDecode = decodeURIComponent(fileName);
    const blob = data.data;
    if ('download' in document.createElement('a')) {
        // 非IE下载
        const a = document.createElement('a');
        a.download = fileNameDecode;
        a.style.display = 'none';
        a.href = URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileNameDecode);
    }
}

export function openNewWindow(url) {
    var a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    a.click();
}


//数组展开  flag:需要返回的数据的key的集合
export function dataExpandList(data={},flag= []){
    let keyCurrent =  Object.keys(data);
    if(flag.length > 0){
        keyCurrent = flag;
    }
    let keys = keyCurrent.filter((item) => !keyCurrent.includes(item+'s'))

    let result = [];
    keys.forEach(item => {
        if( data[item] && Array.isArray(data[item]) ){
            result = result.concat(data[item])
        }else{
            data[item] && result.push(data[item])
        }
    });
    return result;
}

export function getPinyin(val){
    let arrs =  pinyin(val,{style:pinyin.STYLE_NORMAL});
    let text = arrs.map(o=>o.join(''));
    return text.join('');
}


// export function weChatShare(params = {
//     url: location.href,
//     title: document.title,
//     desc: document.title,
//     imgUrl: 'https://cdn.gear.chilunyc.com/static/homepage/images/report-share.png',
// }){

//     OauthApi.getWechat({
//         url: params.url
//     }).then((res)=>{
//         if(res.result){
//             let wxShareData = res.data;
//             wx.config({
//                 debug: false,
//                 appId: wxShareData.appId,
//                 timestamp: wxShareData.timestamp,
//                 nonceStr: wxShareData.nonceStr,
//                 signature: wxShareData.signature,
//                 jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'hideMenuItems', 'hideOptionMenu']
//             });

//             wx.ready(function () {
//                 wx.hideMenuItems({menuList: ['menuItem:share:qq', 'menuItem:share:weiboApp', 'menuItem:favorite', 'menuItem:share:facebook', 'menuItem:share:QZone', 'menuItem:editTag', 'menuItem:delete', 'menuItem:copyUrl', 'menuItem:originPage', 'menuItem:readMode', 'menuItem:openWithQQBrowser', 'menuItem:openWithSafari', 'menuItem:share:email']});
//                 wx.onMenuShareAppMessage({
//                     title: params.title,
//                     desc: params.desc,
//                     link: params.url,
//                     imgUrl: params.imgUrl,
//                     type: '',
//                     dataUrl: '',
//                     success: function () {
//                     },
//                     cancel: function () {
//                     },
//                 });
//                 wx.onMenuShareTimeline({
//                     title: params.title,
//                     desc: params.desc,
//                     link: params.url,
//                     imgUrl: params.imgUrl,
//                     type: '',
//                     dataUrl: '',
//                     success: function () {
//                     },
//                     cancel: function () {
//                     },
//                 });
//             });
//         }

//     })
// }


//时间轴排序
export function sortDaysList(datas){
    let data = datas.sort((a,b)=>{
        if(a.start_date > b.start_date){
            return 1
        }else if(a.start_date < b.start_date){
            return -1
        }else{
            if(a.end_date > b.end_date){
                return 1
            }else if(a.end_date < b.end_date){
                return -1
            }else{
                return 1
            }
        }
    })
    let arr = [];
    data.forEach((currentData,index)=>{
        let noAdd = true;
        //判断是否可以插入在当前数组之中 如果可以就插入并且结束这一个循环 不可以就继续循序知道全部循环完毕
        for(var i = 0 ; i < arr.length ; i++){
            let items = arr[i];
            if( items && items.length>0 ){
                /*//最前面
                if( items[0].start_date > currentData.end_date ){
                    noAdd = false;
                    items.splice(0,0,currentData)
                    break;
                }*/
                //最后面
                if( items[items.length-1].end_date < currentData.start_date ){
                    noAdd = false;
                    items.push(currentData)
                    break;
                }
                /*//中间
                for(let j = 0 ; j<items.length;j++ ){
                    if( items[j+1] && items[j].end_date < currentData.start_date && items[j+1].start_date > currentData.end_date ){
                        noAdd = false;
                        items.splice(j+1,0,currentData)
                        break;
                    }
                }*/

                if(!noAdd){
                    break;
                }
            }
        }
        //如果没有添加 向后追加一个新的数组
        if(noAdd){
            arr.push([currentData]);
        }
    })
    return arr
}


// //获取法定节假日   years:最近几年的数据   默认是最近3年的数据
// export function getHolidayDate(years){
//     let year_num = years ? years :2;
//     //获取最近5年的法定假日数据
//     let promise_all = [];
//     let year = moment().year();
//     for(let i = year - year_num; i <= year; i++){
//         let promise_item = new Promise((resolve, reject) => {
//             get(`https://timor.tech/api/holiday/year/${i}`).then(res => {
//                 resolve(res);
//             }).catch(error => {
//                 resolve({});
//             })
//         })
//         promise_all.push(promise_item);
//     }
//     return new Promise((resolve => {
//         Promise.all(promise_all).then(res => {
//             let holiday = {};
//             for(let i = year - year_num,j = 0; i <= year,j < res.length; i++,j++){
//                 holiday[i] = res[j];
//             }
//             resolve(holiday)
//         }).catch(error =>{
//             resolve({})
//         })
//     }))
// }

// //是否为节假日双休日
// export function isHolidayDate(date){
//     let holiday_year = moment(date).format("YYYY");
//     let holiday_key = moment(date).format('MM-DD');

//     let holiday_year_data = holiday[holiday_year] && holiday[holiday_year].holiday || {};
//     let isHoliday = holiday_year_data[holiday_key] && holiday_year_data[holiday_key].holiday;

//     let isWeek = moment(date).day() == 6 || moment(date).day() == 0;

//     if(isHoliday || (isWeek && isHoliday != false)){
//         return true;
//     }
//     return false;
// }

// //日期内相差多少个工作日 include是否包含第一天
// export function getWorkDays(start,end,isInclude = false){
//     if(!start || !end){
//         return 0
//     }
//     let nums = moment(end).diff(moment(start), "days") || 0;
//     if(isInclude){
//         nums+=1;
//     }
//     let sumNums = 0;
//     for( let i = 0 ; i < nums ; i++ ){
//         if(!isHolidayDate(moment(start).add(i, 'days'))){
//             sumNums += 1;
//         }
//     }
//     return sumNums
// }